import { useEffect } from 'react'

import { useUser } from '@clerk/remix'
import * as Sentry from '@sentry/remix'
import LogRocket from 'logrocket'

/**
 * Custom hook to initialize LogRocket for user monitoring.
 *
 * Client only.
 */
export function useUserMonitoring() {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`
                             .
                            | \\/|
    (\\   _                  ) )|/|
        (/            _----. /.'.'
  .-._________..      .' @ _\\  .'
  '.._______.   '.   /    (_| .')
    '._____.  /   '-/      | _.'      S C R E E C H !
     '.______ (         ) ) \\
       '..____ '._       )  )
          .' __.--\\  , ,  // ((
          '.'  mrf|  \\/   (_.'(
                  '   \\ .'
                   \\   (
                    \\   '.
                     \\ \\ '.)
                      '-'-'

          L o r i k e e t . A I`)
    if (import.meta.env.MODE !== 'development') {
      void import('logrocket').then(({ default: LogRocket }) => {
        LogRocket.init('viltrr/optech-app')
      })
    }
  }, [])
}

/**
 * Client only.
 */
export const useIdentifyUser = () => {
  const { user, isSignedIn } = useUser()

  useEffect(() => {
    if (!user) {
      return
    }

    if (!isSignedIn) {
      return
    }

    const { id, firstName, lastName, emailAddresses } = user
    const emailResource = emailAddresses[0] || {}
    LogRocket.identify(id, {
      firstName: String(firstName),
      lastName: String(lastName),
      email: emailResource.emailAddress,
    })
    Sentry.setUser({
      id,
      firstName: String(firstName),
      lastName: String(lastName),
      email: emailResource.emailAddress,
    })
  }, [user, isSignedIn])

  useEffect(() => {
    if (!isSignedIn) {
      return
    }

    if (!window.lorikeet) {
      return
    }

    void fetch('/api/token')
      .then((response) => {
        if (response.ok) {
          return response.text()
        }
      })
      .then((token) => {
        if (token) {
          window.lorikeet?.init({
            token,
          })
        }
      })
  }, [isSignedIn])
}
